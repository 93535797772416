import React from "react";
import { Page } from "./Page";
import "./Home.scss";
import { Image } from "../components/Images/Image";
import { ROUTE as hundredYearsWarRoute } from "./HundredYearsWar/HundredYearsWar";
import { ROUTE as supernovaInTheEastRoute } from "./SupernovaInTheEast/SupernovaInTheEast";
import { ROUTE as punicWarsRoute } from "./PunicWars/PunicWars";
import { ROUTE as quizRoute } from "./Quiz/Quiz";
import { getDynamicLink } from "../data/routeUtils";
import { Link } from "react-router-dom";
import { Logo } from "../components/Logo/Logo";
import { QueueItem } from "./SupernovaInTheEast/QueueItem";
import { ScreenRotate } from "../components/Icons/ScreenRotate";
import { Facebook } from "../components/Icons/Facebook";
import { Twitter } from "../components/Icons/Twitter";
import { Copy } from "../components/Copy/Copy";

export const ROUTE = "/";

export const Home: React.FunctionComponent = () => (
  <Page
    title="Maps"
    className="home"
    description="Interactive maps of the Punic Wars, Imperial Japan 1870 - 1945 and the Hundred Years' War"
    canonical="https://interactive-history.app/"
  >
    <section className="home-grid">
      <article className="headers-wrapper">
        <h1>
          <Logo /> Interactive History
        </h1>

        <div className="screen-mode-info">
          The maps are probably best viewed with the device/screen in{" "}
          <u>landscape</u> mode
          <div className="screen-mode-icon">
            <ScreenRotate />
          </div>
        </div>
      </article>
      <article className="maps-wrapper">
        <h2>Maps</h2>

        <div className="banner banner-supernova-in-the-east">
          <Link to={getDynamicLink(supernovaInTheEastRoute, { year: "1870" })}>
            <div className="banner-overlay">
              <h3>Imperial Japan 1870 - 1945</h3>
              <div>
                <span className="fake-ghost-button">Go to map</span>
              </div>
            </div>

            <Image
              src="/images/japan_banner_500x200.webp"
              ratio={1000 / 400}
              alt="Imperial Japan - banner"
            />
          </Link>
        </div>

        <div className="banner banner-punic-wars">
          <Link to={getDynamicLink(punicWarsRoute, { year: "264bc" })}>
            <div className="banner-overlay">
              <h3>The Punic Wars</h3>
              <div>
                <span className="fake-ghost-button">Go to map</span>
              </div>
            </div>

            <Image
              src="/images/punic_banner_500x200.webp"
              ratio={1000 / 400}
              alt="The Punic wars - banner"
            />
          </Link>
        </div>

        <div className="banner banner-hundred-years-war">
          <Link to={getDynamicLink(hundredYearsWarRoute, { year: "1337" })}>
            <div className="banner-overlay">
              <h3>Hundred Years' War</h3>
              <div>
                <span className="fake-ghost-button">Go to map</span>
              </div>
            </div>

            <Image
              src="/images/hyw_banner_500x200.webp"
              ratio={1000 / 400}
              alt="Hundred years war - banner"
            />
          </Link>
        </div>

        <div className="disclaimer">
          If you find something that seems incorrect, e.i. information,
          functionality or spelling, just send me an{" "}
          <a href="mailto:average.productions.ab@gmail.com">email</a> and I'll
          look into it. Stay safe.
        </div>
      </article>

      <article className="quiz-wrapper">
        <div className="quiz-wrapper-top">
          <h2>Quiz</h2>
          <div className="tag tag-new hide">New</div>
        </div>
        From <i>Cyrus the Great</i> to <i>Attila</i>, do you know your ancient
        history?
        <Link className="as-button block" to={quizRoute}>
          Go to quiz
        </Link>
      </article>

      <article className="support-wrapper">
        <h2>
          Support by sharing <span>❤️</span>
        </h2>
        <Copy />
        <div className="share-icons">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Finteractive-history.app%2F"
            target="_blank"
            rel="noreferrer"
            className="share-icon-facebook"
            aria-label="Share on Facebook"
          >
            <Facebook />
          </a>
          <a
            href="https://twitter.com/intent/tweet?url=http%3A%2F%2Finteractive-history.app%2F&text=Interactive%20maps%20of%20history's%20great%20wars"
            target="_blank"
            rel="noreferrer"
            className="share-icon-twitter"
            aria-label="Share on X"
          >
            <Twitter />
          </a>
        </div>
      </article>

      <article className="queue-wrapper">
        <div className="queue progress-top">
          <h2>In progress</h2>
          <span className="updated">2024-10-06</span>
        </div>

        {/* <div className="queue-header">
                <i className="queue-header-name">Map</i>
                <i className="queue-header-chance">Progress</i>
              </div> */}
        <ul>
          <QueueItem
            name={
              <span>
                <i>The Ancient World</i>
              </span>
            }
            chance={2}
            inverted={true}
          />
        </ul>
        <p>
          So, I have a new assignment and I'm putting in a lot of work there.
          That means, unfortunately, that this project has stalled. I promise
          that every minute I can spare will go into this project. But again, it
          is not likely that I'd be able to ship the maps of{" "}
          <i>the Ancient World</i> for some time. Sorry. <br />
          Take care.
        </p>

        {/* <div className="queue">
              <h2>Queue</h2>

              <div className="queue-header">
                <i className="queue-header-name">Map name</i>
                <i className="queue-header-chance">Probability</i>
              </div>
              <ul>
                <QueueItem name="the Mongolian empire" chance={20} />
                <QueueItem name="the Napoleonic wars" chance={15} />
                <QueueItem name="Something else..." chance={65} />
              </ul>
            </div> */}
      </article>

      <article className="faq">
        <ul>
          <li>
            <b>Seems like ... is not working. Will you update?</b>
            <p>
              Yes, send me an{" "}
              <a href="mailto:average.productions.ab@gmail.com">email</a> and
              I'll look into it.
            </p>
          </li>
          <li>
            <b>Seems like ... is incorrect. Will you update?</b>
            <p>
              Yes, send me an{" "}
              <a href="mailto:average.productions.ab@gmail.com">email</a> and
              I'll look into it.
            </p>
          </li>
          <li>
            <b>
              You frequently reference Wikipedia. There are more reliable
              sources. Will you change?
            </b>
            <p>
              The information on Wikipedia is sometimes questionable/incorrect,
              but, for a rudimentary overview, it is, I think, in most cases,
              sufficient. Indeed, visitors with an in depth historical knowledge
              might find these maps too simplific as well.
            </p>
          </li>
          <li>
            <b>Cookies. Do you use them?</b>
            <p>
              Well, yes. I use Google Analytics for some rudimentary analysis.
              That's it. I don't track individual users and never will. In order
              to improve this site, in the future, I might extend Google
              Analytics or add some other statistics framework but, again, never
              to track individual users.
            </p>
          </li>
          <li>
            <b>
              Your English, attempt to, is bad. It's lika a mix of that of Yoda
              and the average rap star. Didn't you go to school?
            </b>
            <p>Fanks. I'm form Swedish. I tries.</p>
          </li>
        </ul>
      </article>
    </section>
  </Page>
);
